import React from 'react';
import { LoadableComponent } from '@loadable/component';

export enum RouteEvent {
  LoadStart = 'loadStart',
  LoadEnd = 'loadEnd',
}

export enum RouterMeasure {
  Render = 'render',
}

export interface RouteEventDetail {
  pathname: string;
  duration?: number;
}

const assertLoadableComponent = (
  component: any
): component is LoadableComponent<any> => {
  return Boolean((component as unknown as LoadableComponent<any>).load);
};

export const createRouteEvent = (
  type: RouteEvent,
  detail: RouteEventDetail
) => {
  const event = new CustomEvent(type, {
    detail,
  });

  document.dispatchEvent(event);
};

export const notifyRouteLoadEvents = (
  component: React.ComponentType<any> | LoadableComponent<any>,
  pathname: string
) => {
  createRouteEvent(RouteEvent.LoadStart, { pathname });

  const startMark = Date.now();

  if (assertLoadableComponent(component)) {
    component.load().then(() => {
      const endMark = Date.now();

      createRouteEvent(RouteEvent.LoadEnd, {
        pathname,
        duration: endMark - startMark,
      });
    });
  } else {
    createRouteEvent(RouteEvent.LoadEnd, {
      pathname,
      duration: 0,
    });
  }
};
