import { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import queryString from 'query-string';

import {
  QueryKeys,
  useSystemSearchQueries,
} from 'hooks/useSystemSearchQueries';

export const useClickIdAssign = () => {
  const { search } = useLocation();
  const history = useHistory();

  const {
    clickIdQuery,
    fbclidQuery,
    ttclidQuery,
    twclidQuery,
    gclidQuery,
    wbraidQuery,
    gbraidQuery,
  } = useSystemSearchQueries();

  useEffect(() => {
    const matchingParam =
      fbclidQuery.value ||
      gclidQuery.value ||
      wbraidQuery.value ||
      gbraidQuery.value ||
      ttclidQuery.value ||
      twclidQuery.value;

    if (matchingParam && !clickIdQuery.value) {
      const queryStringWithoutCLickId = queryString.exclude(search, [
        QueryKeys.ClickId,
      ]);

      history.replace({
        search: `${queryStringWithoutCLickId}&${QueryKeys.ClickId}=${matchingParam}`,
      });
    }
  }, [
    clickIdQuery.value,
    fbclidQuery.value,
    gbraidQuery.value,
    gclidQuery.value,
    history,
    search,
    ttclidQuery.value,
    twclidQuery.value,
    wbraidQuery.value,
  ]);
};
