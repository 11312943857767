import React, { memo, useMemo } from 'react';

import { MirrorService } from 'services/MirrorService';

import { usePWAProfilesQuery } from 'hooks/queries/usePWAProfilesQuery';

import profile1 from 'assets/wizards/profile1.webp';
import profile2 from 'assets/wizards/profile2.webp';
import profile3 from 'assets/wizards/profile3.webp';
import profile4 from 'assets/wizards/profile4.webp';

import { BaseButton, ButtonTypes } from 'components/base/BaseButton';
import { Spinner, SpinnerTypes } from 'components/base/Spinner';
import { Favicon } from 'components/shared/Favicon';

import css from './promotePWAPopup.module.sass';

const DEFAULT_PROFILES_LIST = [
  { photo: profile1 },
  { photo: profile2 },
  { photo: profile3 },
  { photo: profile4 },
];

const MAX_PROFILES_COUNT = 4;

interface Props {
  onSubmit: () => void;
}

export const WizardPopupContent: React.FC<Props> = memo(({ onSubmit }) => {
  const { data, isLoading } = usePWAProfilesQuery();

  const profilesList = useMemo(() => {
    return [
      ...(data?.profiles || []).map((profileItem) => ({
        photo: profileItem.main_photo?.profile_url,
      })),
      ...DEFAULT_PROFILES_LIST,
    ].slice(0, MAX_PROFILES_COUNT);
  }, [data?.profiles]);

  return (
    <div className={css.wizardRoot}>
      {isLoading && (
        <div className={css.loader}>
          <Spinner size="24px" type={SpinnerTypes.Dark} />
        </div>
      )}

      {!isLoading && (
        <div className={css.imagesWrapper}>
          {profilesList.map((profileItem, index) => (
            <div className={css.imageWrapper} key={index}>
              <img
                src={MirrorService.resolveImagePath(profileItem.photo)}
                className={css.image}
                alt="ladies"
              />
            </div>
          ))}
        </div>
      )}

      <div>
        <p className={css.title}>Stay just a tap away from connecting.</p>
        <p className={css.text}> Enjoy instant access anytime, anywhere!</p>
      </div>

      <BaseButton
        type={ButtonTypes.Transparent}
        className={css.wizardButton}
        onClick={onSubmit}
      >
        Add to Home Screen
      </BaseButton>
      <div className={css.guide}>
        <div className={css.guideMock} />
        <div className={css.guideMock} />
        <div className={css.guideMock} />
        <div className={css.guideMock} />
        <div className={css.guideMock} />
        <div className={css.guideMock} />
        <div className={css.guideMock}>
          <Favicon className={css.guideMockImage} />
        </div>
      </div>
    </div>
  );
});
