export const getScreenSizes = (): { width: number; height: number } => {
  const width = window.screen.availWidth;
  const height = window.screen.availHeight;

  return { width, height };
};

export const getScreenOrientation = (): 'portrait' | 'landscape' => {
  return window.matchMedia('(orientation: portrait)').matches
    ? 'portrait'
    : 'landscape';
};

export const getScreenOrientationWithSizes = () => {
  return {
    orientation: getScreenOrientation(),
    width: getScreenSizes().width,
    height: getScreenSizes().height,
  };
};

const isFacebookWebView = () => {
  try {
    const ua = (navigator?.userAgent || navigator?.vendor)?.toLowerCase();

    return /fban|fbav|fbdv/.test(ua);
  } catch {
    return false;
  }
};

const isInstagramWebView = () => {
  try {
    const ua = (navigator?.userAgent || navigator?.vendor)?.toLowerCase();

    return /instagram/.test(ua);
  } catch {
    return false;
  }
};

const isIos = () => {
  try {
    const ua = (navigator?.userAgent || navigator?.vendor)?.toLowerCase();

    return (
      (/iphone|ipad|ipod/.test(ua) &&
        !!navigator.platform &&
        /ipad|iphone|ipod|/.test(navigator.platform?.toLowerCase())) ||
      (navigator.maxTouchPoints &&
        navigator.maxTouchPoints > 1 &&
        !!navigator.platform &&
        /macintel/.test(navigator.platform?.toLowerCase()))
    );
  } catch {
    return false;
  }
};

const isAndroid = () => {
  try {
    const ua = (navigator?.userAgent || navigator?.vendor)?.toLowerCase();

    return /(android)/i.test(ua);
  } catch {
    return false;
  }
};

export const getIsAndroidWebView = () => {
  try {
    if (!isAndroid()) return false;

    return isFacebookWebView() || isInstagramWebView();
  } catch {
    return false;
  }
};

export const getIsIosWebView = () => {
  try {
    if (!isIos()) return false;

    return isFacebookWebView() || isInstagramWebView();
  } catch {
    return false;
  }
};
