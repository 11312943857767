import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import cx from 'classnames';

import { Package as PackageType } from 'types/interfaces/payment/Package';

import { getPackagePrice } from 'helpers/price';
import { getCreditsCurrencySelector } from 'store/payment/selectors';

// import { ReactComponent as ArrowDownIcon } from 'assets/payment/arrow-down.svg';
import creditsIcon from 'assets/payment/credits-l.png';
import { ReactComponent as DiscountIcon } from 'assets/payment/discount.svg';

import css from './packageV2TypeRow.module.sass';

type PropTypes = {
  pack: PackageType;
  isInitPackage: boolean;
  onClick?: () => void;
};

export const PackageV2TypeRow: React.FC<PropTypes> = ({
  pack,
  isInitPackage,
  onClick,
}) => {
  const { priceFullWithCurrency, displayPriceWithCurrency } = useMemo(
    () => getPackagePrice(pack),
    [pack]
  );

  const creditsCurrency = useSelector(getCreditsCurrencySelector);

  return (
    <>
      {/* {isInitPackage && (
        <div className={css.spoiler}>
          <ArrowDownIcon className={css.spoilerIcon} />
          <p className={css.spoilerText}>Welcome Offer</p>
        </div>
      )} */}
      {isInitPackage && <h3 className={css.headerWelcome}>Welcome Offer</h3>}

      <div
        className={cx(css.root, {
          [css.active]: pack.active,
        })}
        onClick={onClick}
      >
        {pack.discount > 0 && !isInitPackage && (
          <h3 className={css.header}> SAVE {pack.discount}%</h3>
        )}
        <div className={css.main}>
          <div className={css.creditsInfo}>
            <img className={css.incomeIcon} src={creditsIcon} />
            <div className={css.incomeBalance}>
              <p className={css.incomeCredits}>{pack.credits_buy}</p>
              <p className={css.incomeCurrency}>{creditsCurrency}</p>
            </div>
          </div>
          <div className={css.priceInfo}>
            <div>
              <div className={css.initPrice}>{displayPriceWithCurrency}</div>
              {!!pack.discount && (
                <p className={css.fullPrice}>
                  <span>{priceFullWithCurrency}</span>
                  {/* Old price */}
                </p>
              )}
            </div>
          </div>
          {!!pack.discount && (
            <div className={css.discountInfo}>
              <p className={css.discountValue}>-{pack.discount}%</p>
              <DiscountIcon className={css.discountIcon} />
            </div>
          )}
        </div>
      </div>
    </>
  );
};
