import { useCallback } from 'react';
import queryString from 'query-string';

import { setXRefererUrl } from 'api/httpClient';
import { useLocationChange } from 'hooks/useLocationChange';

import { QueryKeys } from './useSystemSearchQueries';

export const useXRefererUrl = () => {
  const handleSetXRefererUrl = useCallback(
    ({ currentUrl }: { currentUrl: string }) => {
      const {
        [QueryKeys.Fbclid]: fbclid,
        [QueryKeys.Ttclid]: ttclid,
        [QueryKeys.Twclid]: twclid,
        [QueryKeys.Gclid]: gclid,
        [QueryKeys.ClickId]: clickId,
        [QueryKeys.Wbraid]: wbraid,
        [QueryKeys.Gbraid]: gbraid,
      } = queryString.parse(currentUrl);

      let finalXReferer = currentUrl;

      const matchingParam =
        fbclid || gclid || wbraid || gbraid || ttclid || twclid;

      if (matchingParam && !clickId) {
        finalXReferer = queryString.exclude(finalXReferer, [QueryKeys.ClickId]);
        finalXReferer = `${finalXReferer}&${QueryKeys.ClickId}=${matchingParam}`;
      }

      setXRefererUrl(finalXReferer);
    },
    []
  );

  useLocationChange(handleSetXRefererUrl);
};
