import { useCallback, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';

import { MirrorService } from 'services/MirrorService';

import { AuthApi, OnlineType } from 'api/AuthApi';
import { getAccessTokenDataFromStorage } from 'helpers/auth';
import { LAST_ONLINE_TS_STORAGE_KEY } from 'helpers/constants';
import { setSessionStorageItem } from 'helpers/sessionStorage';
import { getIsCommonStateLoaded } from 'store/auth/selectors';
import { getIsEnabledOnlineOnClickSelector } from 'store/common/selectors';

import { useInactiveBrowserTab } from './useInactiveBrowserTab';

const PING_INTERVAL = 60 * 1000;

export const useOnlinePingV2 = (isAuthenticated: boolean) => {
  const lastPing = useRef<number>(0);

  const isCommonStateLoaded = useSelector(getIsCommonStateLoaded);
  const isEnabledOnlineOnClick = useSelector(getIsEnabledOnlineOnClickSelector);

  const { isBrowserTabActive } = useInactiveBrowserTab();

  const handlePingOnlineSend = useCallback(async () => {
    if (!isAuthenticated || !isCommonStateLoaded) return;

    const eventTs = Date.now();

    if (eventTs - lastPing.current > PING_INTERVAL) {
      setSessionStorageItem(LAST_ONLINE_TS_STORAGE_KEY, eventTs);

      lastPing.current = eventTs;

      AuthApi.pingOnlineV2(OnlineType.RealOnline);

      if (isEnabledOnlineOnClick) {
        const siteVersion = await AuthApi.getSiteVersion();

        AuthApi.pingOnline(siteVersion);
      }
    }
  }, [isAuthenticated, isCommonStateLoaded, isEnabledOnlineOnClick]);

  const handlePingOfflineSend = useCallback(() => {
    if (!isAuthenticated) return;

    if (lastPing.current) {
      lastPing.current = 0;

      AuthApi.pingOnlineV2(OnlineType.RealOffline);
    }
  }, [isAuthenticated]);

  const handleBeaconOfflineSend = useCallback(() => {
    if (!isAuthenticated || !lastPing.current) return;

    lastPing.current = 0;

    const { token: accessToken } = getAccessTokenDataFromStorage();

    if (!accessToken) return;

    try {
      const blob = new Blob(
        [
          JSON.stringify({
            type: 4,
          }),
        ],
        {
          type: 'application/json',
        }
      );

      navigator.sendBeacon(
        `${MirrorService.apiUrl}/ping/v2?token=${accessToken}`,
        blob
      );
    } catch (error) {
      console.error(error);
    }
  }, [isAuthenticated]);

  useEffect(() => {
    if (!isBrowserTabActive) {
      handlePingOfflineSend();
    }
  }, [handlePingOfflineSend, isBrowserTabActive]);

  useEffect(() => {
    if (isBrowserTabActive) {
      handlePingOnlineSend();
    }
  }, [handlePingOnlineSend, isBrowserTabActive]);

  useEffect(() => {
    window.addEventListener('beforeunload', handleBeaconOfflineSend);

    return () => {
      window.removeEventListener('beforeunload', handleBeaconOfflineSend);
    };
  }, [handleBeaconOfflineSend]);

  useEffect(() => {
    document.addEventListener('click', handlePingOnlineSend);
    document.addEventListener('scroll', handlePingOnlineSend);
    document.addEventListener('mousemove', handlePingOnlineSend);
    document.addEventListener('keydown', handlePingOnlineSend);
    document.addEventListener('touchstart', handlePingOnlineSend);

    return () => {
      document.removeEventListener('click', handlePingOnlineSend);
      document.removeEventListener('scroll', handlePingOnlineSend);
      document.removeEventListener('mousemove', handlePingOnlineSend);
      document.removeEventListener('keydown', handlePingOnlineSend);
      document.removeEventListener('touchstart', handlePingOnlineSend);
    };
  }, [handlePingOnlineSend]);
};
