import { AppThunk } from 'store';

import { AuthApi } from 'api/AuthApi';
import { SettingsApi } from 'api/SettingsApi';
import { WizardApi } from 'api/WizardApi';
import { setUser } from 'store/auth/authSlice';
import { setCreditsInfo } from 'store/payment/paymentSlice';
import { setPayment } from 'store/payment/thunks';

import {
  setInitState,
  setIsEnabledNotificationsSound,
  setIsWizardFinished,
  setLoading,
} from './commonSlice';

export const init = (): AppThunk => async (dispatch, getState) => {
  const state = getState();

  dispatch(setLoading(true));

  const initData = await AuthApi.init({
    from: state.common.prevPagePathname,
  });

  dispatch(
    setPayment({
      ...initData.payment,
      theme: initData.theme,
      credits: initData.credits,
      subscription: initData.subscription,
    })
  );

  dispatch(
    setCreditsInfo({
      countOfPays: initData.credit_pays.count_of_pays,
      refillPackage: initData.refill.refill_package,
      isRefillEnabled: initData.refill.refill_enabled,
    })
  );

  dispatch(setUser(initData.user));

  dispatch(
    setInitState({
      loading: false,
      unreadCount: {
        dialogs: initData?.unread_counters?.dialogs || 0,
        notifications: initData?.unread_counters?.notifications || 0,
        inmails: initData?.unread_counters?.inmails || 0,
        chat_requests: initData?.unread_counters?.chat_requests || 0,
      },
      currentLang: initData.site_language.value,
      supportedLangs: Object.values(initData.site_language.options),
      translations: {
        isEnabled: initData.translation.enabled,
        buildVersion: initData.translation.build_id,
      },
      featureFlags: {
        isEnabledNotificationsSound: initData.sound_notification,
        isEnabledChatSayHelloInit: false,
        isEnabledIntentForm: !!initData.features.NEW_PAYMENT_FORM,
        isEnabledWebViewRedirect: !!initData.features.FB_REDIRECT,
        isEnabledPurchasePopup: !!initData.features.PURCHASE_POP_UP,
        isEnabledOnlineOnClick: !!initData.features.ONLINE_ON_CLICK,
        isEnabledPWAAfterPurchase: !!initData.features.PWA_AFTER_PURCHASE,
      },
      isWizardFinished: initData.wizard_finish,
    })
  );
};

export const updateNotificationsSoundThunk =
  (payload: boolean): AppThunk =>
  async (dispatch) => {
    await SettingsApi.updateNotificationsSoundFlag(payload);

    dispatch(setIsEnabledNotificationsSound(payload));
  };

export const finishWizardThunk = (): AppThunk => async (dispatch) => {
  try {
    await WizardApi.wizardFinish();
  } finally {
    dispatch(setIsWizardFinished(true));
  }
};
