import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { CreditsInfo } from 'types/interfaces/payment/CreditsInfo';
import { OrderInfo } from 'types/interfaces/payment/OrderInfo';
import { Package } from 'types/interfaces/payment/Package';
import { SubscriptionInfo } from 'types/interfaces/payment/SubscriptionInfo';

import { PackagesThemeGroups } from 'api/AuthApi';

interface PaymentError {
  title: string | null;
  message: string;
}

interface BalanceInfo {
  amount: number;
  spend_amount: number;
  currency: string;
}

type PaymentState = {
  paymentOrderInfo: OrderInfo | null;
  paymentError: PaymentError | null;
  verifyUrl: string | null;
  creditPackages: Package[];
  recurrentPackages: Package[];
  giftPackages: Package[];
  creditsInfo: CreditsInfo;
  credits: BalanceInfo;
  selectedUpSalePackagesIds: string[];
  subscriptionInfo: SubscriptionInfo;
  theme: PackagesThemeGroups;
};

const initialState: PaymentState = {
  paymentOrderInfo: null,
  paymentError: null,
  verifyUrl: null,
  creditPackages: [],
  recurrentPackages: [],
  giftPackages: [],
  creditsInfo: {
    countOfPays: 0,
    isRefillEnabled: false,
    refillPackage: null,
  },
  credits: {
    amount: 0,
    spend_amount: 0,
    currency: 'credits',
  },
  selectedUpSalePackagesIds: [],
  subscriptionInfo: {
    can_change_subscription: null,
    current_subscription_package: null,
    next_amount: null,
    next_charge: null,
    next_discount: null,
  },
  theme: PackagesThemeGroups.Default,
};

type Predicate<T> = (arg: T) => boolean;

const setActiveIf =
  (predicate: Predicate<Package>): ((pack: Package) => Package) =>
  (pack) =>
    predicate(pack) ? { ...pack, active: true } : { ...pack, active: false };

const paymentSlice = createSlice({
  name: 'payment',
  initialState,
  reducers: {
    setPaymentOrderInfo(state, action: PayloadAction<OrderInfo | null>) {
      state.paymentOrderInfo = action.payload;
    },
    setPaymentError(state, action: PayloadAction<PaymentError | null>) {
      state.paymentError = action.payload;
    },
    setVerifyUrl(state, action: PayloadAction<string | null>) {
      state.verifyUrl = action.payload;
    },

    // ? default credit payment packages
    setCreditPackages(state, action: PayloadAction<Package[]>) {
      state.creditPackages = action.payload;
    },

    // ? default gift payment packages
    setGiftPackages(state, action: PayloadAction<Package[]>) {
      state.giftPackages = action.payload;
    },

    setPackageActive(state, action: PayloadAction<string>) {
      const { payload: id } = action;

      if (state.creditPackages.find((packItem) => packItem.id === id))
        state.creditPackages = state.creditPackages.map(
          setActiveIf((pack) => pack.id === id)
        );

      if (state.giftPackages.find((packItem) => packItem.id === id))
        state.giftPackages = state.giftPackages.map(
          setActiveIf((pack) => pack.id === id)
        );
    },

    // ? recurrent credit payment packages 'subscription'
    setSubscriptionPackages(state, action: PayloadAction<Package[]>) {
      state.recurrentPackages = action.payload;
    },
    setSubscriptionPackageActive(state, action: PayloadAction<string>) {
      const { payload: id } = action;

      state.recurrentPackages = state.recurrentPackages.map(
        setActiveIf((pack) => pack.id === id)
      );
    },

    // ? payment info
    setCredits(state, action: PayloadAction<BalanceInfo>) {
      state.credits = action.payload;
    },
    setPackagesTheme(state, action: PayloadAction<PackagesThemeGroups>) {
      state.theme = action.payload;
    },
    setCreditsInfo(state, action: PayloadAction<CreditsInfo>) {
      state.creditsInfo = action.payload;
    },
    updateCreditsInfo(state, action: PayloadAction<Partial<CreditsInfo>>) {
      state.creditsInfo = { ...state.creditsInfo, ...action.payload };
    },
    setSubscriptionInfo(state, action: PayloadAction<SubscriptionInfo>) {
      state.subscriptionInfo = action.payload;
    },
    setCreditsAmount(state, action: PayloadAction<number>) {
      state.credits.amount = action.payload;
    },
    setSpendAmount(state, action: PayloadAction<number>) {
      state.credits.spend_amount = action.payload;
    },
    addCreditsAmount(state, action: PayloadAction<number>) {
      state.credits.amount += action.payload;
    },
  },
});

export const {
  setPaymentOrderInfo,
  setPaymentError,
  setVerifyUrl,

  setCreditPackages,
  setGiftPackages,
  setPackageActive,

  setSubscriptionPackages,
  setSubscriptionPackageActive,

  setCredits,
  setPackagesTheme,
  setCreditsInfo,
  updateCreditsInfo,
  setSubscriptionInfo,
  setCreditsAmount,
  setSpendAmount,
  addCreditsAmount,
} = paymentSlice.actions;

export const payment = paymentSlice.reducer;
