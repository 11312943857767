import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import cx from 'classnames';

import { Package as PackageType } from 'types/interfaces/payment/Package';

import { getPackagePrice } from 'helpers/price';
import { getCreditsCurrencySelector } from 'store/payment/selectors';

import { ReactComponent as ArrowDownIcon } from 'assets/payment/arrow-down.svg';
import creditsIcon from 'assets/payment/credits-l.png';
import { ReactComponent as DiscountIcon } from 'assets/payment/discount.svg';

import css from './packageV2TypeColumn.module.sass';

type PropTypes = {
  pack: PackageType;
  isMostPopular: boolean;
  onClick?: () => void;
};

export const PackageV2TypeColumn: React.FC<PropTypes> = ({
  pack,
  isMostPopular,
  onClick,
}) => {
  const { priceFullWithCurrency, displayPriceWithCurrency } = useMemo(
    () => getPackagePrice(pack),
    [pack]
  );

  const creditsCurrency = useSelector(getCreditsCurrencySelector);

  return (
    <>
      {isMostPopular && (
        <div className={css.spoiler}>
          <ArrowDownIcon className={css.spoilerIcon} />
          <p className={css.spoilerText}>Most Popular</p>
        </div>
      )}

      <div
        className={cx(css.root, {
          [css.active]: pack.active,
        })}
        onClick={onClick}
      >
        <h3 className={css.header}>{pack.display_name}</h3>

        <div className={css.main}>
          <div className={css.creditsInfo}>
            <img className={css.incomeIcon} src={creditsIcon} />
            <p className={css.incomeBalance}>{pack.credits_buy}</p>
            <p className={css.incomeCurrency}>{creditsCurrency}</p>
          </div>

          <div className={css.divider} />

          <div className={css.priceInfo}>
            <div className={css.initPrice}>{displayPriceWithCurrency}</div>
            {!!pack.discount && (
              <p className={css.fullPrice}>{priceFullWithCurrency}</p>
            )}
          </div>
        </div>

        {!!pack.discount && pack.active && (
          <div className={css.discountInfo}>
            <div className={css.discountContainer}>
              <p className={css.discountValue}>-{pack.discount}%</p>
              <DiscountIcon className={css.discountIcon} />
            </div>
          </div>
        )}
      </div>
    </>
  );
};
