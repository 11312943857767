import React, {
  createContext,
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Slide, toast, ToastContainer } from 'react-toastify';

import { NotificationTypes } from 'types/enums/NotificationTypes';
import { ToastTypes } from 'types/enums/ToastTypes';

import { isMonetizationPage, isWizardPage } from 'helpers/route';
import {
  ToastActionName,
  useToastAnalytics,
} from 'hooks/analytics/useToastAnalytics';
import { useMedia } from 'hooks/useMedia';
import { getIsAuthenticated } from 'store/auth/selectors';

import {
  NotificationToast,
  NotificationToastProps,
  SystemToast,
  SystemToastProps,
} from 'components/shared/Toast';

import {
  MAX_TOAST_QUEUE_LENGTH,
  MAX_TOASTS_COUNT_AT_TIME,
  NOTIFICATION_TOAST_AUTOCLOSE_TIME,
  SYSTEM_TOAST_AUTOCLOSE_TIME,
  TOAST_QUEUE_UNLOCK_DELAY,
} from './constants';

import css from './styles.module.sass';

type ToastQueueItem =
  | (NotificationToastProps & { type: ToastTypes.Notification })
  | SystemToastProps;

type ToastPriorityLevel = 1 | 2 | 3 | 4;

const getNotificationToastContent = (options: NotificationToastProps) => {
  return <NotificationToast {...options} />;
};

const getSystemToastContent = (options: SystemToastProps) => {
  return <SystemToast {...options} />;
};

const assertSystemToast = (
  toastItem: unknown
): toastItem is SystemToastProps => {
  // @ts-ignore
  if (!toastItem?.notification) return true;

  return false;
};

const getToastPriorityLevel = (
  toastItem: ToastQueueItem
): ToastPriorityLevel => {
  if (assertSystemToast(toastItem)) return 1;

  if (
    toastItem.notification.type === NotificationTypes.PhotoApproved ||
    toastItem.notification.type === NotificationTypes.PhotoRejected
  )
    return 1;

  if (
    toastItem.notification.type === NotificationTypes.Inbox ||
    toastItem.notification.type === NotificationTypes.Message
  )
    return 2;

  if (
    toastItem.notification.type === NotificationTypes.GiftOpen ||
    toastItem.notification.type === NotificationTypes.Visit ||
    toastItem.notification.type === NotificationTypes.MutualLike ||
    toastItem.notification.type === NotificationTypes.Like
  )
    return 3;

  return 4;
};

export const ToastsContext = createContext<{
  showNotificationToast: (payload: NotificationToastProps) => void;
  showSuccessToast: (payload: Omit<SystemToastProps, 'type'>) => void;
  showWarningToast: (payload: Omit<SystemToastProps, 'type'>) => void;
  showErrorToast: (payload: Omit<SystemToastProps, 'type'>) => void;
}>({
  showNotificationToast: () => {},
  showSuccessToast: () => {},
  showWarningToast: () => {},
  showErrorToast: () => {},
});

export const ToastsProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const { isMobile } = useMedia();

  const { pathname } = useLocation();

  const isAuthenticated = useSelector(getIsAuthenticated);

  const [toastQueue, setToastQueue] = useState<ToastQueueItem[]>([]);
  const [isToastQueueLocked, setIsToastQueueLocked] = useState(false);

  const { trackToast } = useToastAnalytics();

  const handleToastQueueUpdate = useCallback(
    (prevToastQueue: ToastQueueItem[], newToast: ToastQueueItem) => {
      if (
        !assertSystemToast(newToast) &&
        prevToastQueue.find((toastItem) => {
          if (assertSystemToast(toastItem)) return false;

          return (
            toastItem.notification.type === newToast.notification.type &&
            toastItem.notification.sender?.ulid_id ===
              newToast.notification.sender?.ulid_id
          );
        })
      ) {
        return prevToastQueue;
      }

      return [...prevToastQueue, newToast]
        .filter((toastItem) => getToastPriorityLevel(toastItem) !== 4) // ? not show 4 lvl priority toasts
        .sort(
          (leftToastItem, rightToastItem) =>
            getToastPriorityLevel(leftToastItem) -
            getToastPriorityLevel(rightToastItem)
        )
        .slice(0, MAX_TOAST_QUEUE_LENGTH);
    },
    []
  );

  const showNotificationToast = useCallback(
    (options: NotificationToastProps) => {
      const newToast: ToastQueueItem = {
        ...options,
        type: ToastTypes.Notification,
      };

      setToastQueue((prevQueue) => handleToastQueueUpdate(prevQueue, newToast));
    },
    [handleToastQueueUpdate]
  );

  const showSuccessToast = useCallback(
    (options: Omit<SystemToastProps, 'type'>) => {
      const newToast: ToastQueueItem = {
        ...options,
        type: ToastTypes.Success,
      };

      setToastQueue((prevQueue) => handleToastQueueUpdate(prevQueue, newToast));
    },
    [handleToastQueueUpdate]
  );

  const showWarningToast = useCallback(
    (options: Omit<SystemToastProps, 'type'>) => {
      const newToast: ToastQueueItem = {
        ...options,
        type: ToastTypes.Warning,
      };

      setToastQueue((prevQueue) => handleToastQueueUpdate(prevQueue, newToast));
    },
    [handleToastQueueUpdate]
  );

  const showErrorToast = useCallback(
    (options: Omit<SystemToastProps, 'type'>) => {
      const newToast: ToastQueueItem = { ...options, type: ToastTypes.Error };

      setToastQueue((prevQueue) => handleToastQueueUpdate(prevQueue, newToast));
    },
    [handleToastQueueUpdate]
  );

  const value = useMemo(() => {
    return {
      showNotificationToast,
      showSuccessToast,
      showWarningToast,
      showErrorToast,
    };
  }, [
    showErrorToast,
    showNotificationToast,
    showSuccessToast,
    showWarningToast,
  ]);

  useEffect(() => {
    if (
      toastQueue.length &&
      !isToastQueueLocked &&
      !isMonetizationPage(pathname) &&
      !isWizardPage(pathname)
    ) {
      setIsToastQueueLocked(true);

      const firstToastInQueue = toastQueue[0];
      const isSystemToast = assertSystemToast(firstToastInQueue);

      const toastContent = isSystemToast
        ? getSystemToastContent(firstToastInQueue)
        : getNotificationToastContent(firstToastInQueue);

      const toastConfig = isSystemToast
        ? {
            autoClose: SYSTEM_TOAST_AUTOCLOSE_TIME,
            hideProgressBar: true,
          }
        : {
            autoClose: NOTIFICATION_TOAST_AUTOCLOSE_TIME,
            hideProgressBar: !firstToastInQueue?.btnText,
          };

      const toastTrackingData = !isSystemToast
        ? {
            uniqueId: firstToastInQueue.notification?.unique_id || null,
            senderId: firstToastInQueue.notification?.sender?.ulid_id || '',
            notificationId: firstToastInQueue.notification?.id,
            notificationTitle: firstToastInQueue.notification?.title || '',
            notificationDescription:
              firstToastInQueue.notification?.description || '',
            messageSharedId:
              firstToastInQueue.notification?.message_shared_id || null,
            inmailSharedId:
              firstToastInQueue.notification?.inmail_shared_id || null,
            sympathyId: firstToastInQueue.notification?.sympathy_id || null,
            userVisitId: firstToastInQueue.notification?.user_visit_id || null,
          }
        : null;

      if (toastTrackingData)
        trackToast({
          action: ToastActionName.Show,
          ...toastTrackingData,
        });

      let toastClicked = false;

      toast(toastContent, {
        onClick: () => {
          toastClicked = true;

          if (toastTrackingData)
            trackToast({
              action: ToastActionName.Click,
              ...toastTrackingData,
            });
        },
        onClose: () => {
          setToastQueue((prevQueue) => prevQueue.slice(1));

          setTimeout(() => {
            setIsToastQueueLocked(false);
          }, TOAST_QUEUE_UNLOCK_DELAY);

          if (!toastClicked && toastTrackingData) {
            trackToast({
              action: ToastActionName.Close,
              ...toastTrackingData,
            });
          }
        },
        ...toastConfig,
      });
    }
  }, [isToastQueueLocked, pathname, toastQueue, trackToast]);

  useEffect(() => {
    return () => {
      if (isAuthenticated) {
        setToastQueue([]);
        toast.clearWaitingQueue();
      }
    };
  }, [isAuthenticated]);

  return (
    <ToastsContext.Provider value={value}>
      {children}
      <ToastContainer
        position="top-right"
        draggable
        draggablePercent={35}
        closeButton={false}
        closeOnClick
        pauseOnHover={!isMobile}
        autoClose={NOTIFICATION_TOAST_AUTOCLOSE_TIME}
        limit={MAX_TOASTS_COUNT_AT_TIME}
        transition={Slide}
        className={css.root}
        toastClassName={css.toast}
        progressClassName={css.progress}
      />
    </ToastsContext.Provider>
  );
};
