import axios from 'axios';

import { MirrorService } from 'services/MirrorService';

export interface AccessTokenData {
  access_token: string;
  expires_in: number;
}

export const RefreshTokenApi = {
  async refreshAccessToken({
    token,
  }: {
    token: string | null;
  }): Promise<AccessTokenData> {
    const { data } = await axios.post<AccessTokenData>(
      token ? `/refresh?token=${token}` : '/refresh',
      {
        app_load_id: window?.appLoadId,
      },
      { withCredentials: true, baseURL: MirrorService.apiUrl }
    );

    return data;
  },
};
