import { ChatMessageFormat } from 'types/enums/chat/ChatMessageFormat';
import {
  ChatGiftMessage,
  ChatIcebreakerMessage,
  ChatMessage,
  ChatPhotoMessage,
  ChatPresentMessage,
  ChatStickerMessage,
  ChatTextMessage,
  ChatVideoMessage,
} from 'types/interfaces/chat/ChatMessage';

export const assertTextMessage = (
  message: ChatMessage
): message is ChatTextMessage => {
  return message.format === ChatMessageFormat.Text;
};

export const assertPhotoMessage = (
  message: ChatMessage
): message is ChatPhotoMessage => {
  return message.format === ChatMessageFormat.Photo;
};

export const assertStickerMessage = (
  message: ChatMessage
): message is ChatStickerMessage => {
  return message.format === ChatMessageFormat.Sticker;
};

export const assertGiftMessage = (
  message: ChatMessage
): message is ChatGiftMessage => {
  return message.format === ChatMessageFormat.Gift;
};

export const assertVideoMessage = (
  message: ChatMessage
): message is ChatVideoMessage => {
  return message.format === ChatMessageFormat.Video;
};

export const assertIcebreakerMessage = (
  message: ChatMessage
): message is ChatIcebreakerMessage => {
  return message.format === ChatMessageFormat.Icebreaker;
};

export const assertPresentMessage = (
  message: ChatMessage
): message is ChatPresentMessage => {
  return message.format === ChatMessageFormat.Present;
};
