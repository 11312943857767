export const PROJECT_COOKIES_SETTINGS_KEY = 'PROJECT_COOKIES_SETTINGS';
export const COOKIES_SETTINGS_SHOWN_KEY = 'COOKIES_SETTINGS_SHOWN';

export const COLOR_TAGS_BACKGROUNDS: string[] = [
  '#FFE2DB',
  '#DBFFE5',
  '#DBEEFF',
  '#E7DBFF',
  '#FFDBFE',
];

export const TRACK_START_APP_SESSION_STORAGE_KEY = 'TRACK_START_APP';
export const TRANSLATIONS_BUILD_SESSION_STORAGE_KEY =
  'TRANSLATIONS_BUILD_VERSION';

export const MAX_PROFILE_PHOTOS_COUNT = 10;

export const LAST_ONLINE_TS_STORAGE_KEY = 'last_online_ts';
