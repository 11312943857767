import React, { FC, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { MirrorService } from 'services/MirrorService';

import { useReactivationPopupAnalytics } from 'hooks/analytics/useReactivationPopupAnalytics';
import { getUser } from 'store/auth/selectors';

import { BaseButton } from 'components/base/BaseButton';
import { Popup } from 'components/shared/Popup';
import { UserAvatar } from 'components/shared/UserAvatar';

import css from './restartSessionPopup.module.sass';

interface Props {
  onClose: () => void;
}

export const RestartSessionPopup: FC<Props> = ({ onClose }) => {
  const user = useSelector(getUser);

  const { trackPopupShow, trackPopupClick } = useReactivationPopupAnalytics();

  const handleClose = () => {
    onClose();

    trackPopupClick();
  };

  useEffect(() => {
    trackPopupShow();
  }, [trackPopupShow]);

  return (
    <Popup>
      <div className={css.popupContent}>
        <UserAvatar
          url={MirrorService.resolveImagePath(user?.photo_url)}
          size="125px"
        />

        <div className={css.textWrapper}>
          <p className={css.inactiveTimeInfo}>You’re inactive for 10 minutes</p>
          <p>To continue, please press the button below.</p>
        </div>

        <div className={css.buttonWrapper}>
          <BaseButton className={css.button} onClick={handleClose}>
            CONTINUE USING
          </BaseButton>
        </div>
      </div>
    </Popup>
  );
};
