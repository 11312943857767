import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { MirrorService } from 'services/MirrorService';
import { PopupOperation } from 'types/enums/PopupOperation';

import { getErrorMessageWithDefault } from 'helpers/errors';
import {
  setIsConfirmationResent,
  setLastConfirmationProfilePopupShown,
} from 'helpers/popups/emailConfirmation';
import {
  IS_PWA_ON_MAIL_CONFIRM_SHOWN,
  PWA_POPUPS,
} from 'helpers/pwa/constants';
import { getSessionStorageItem } from 'helpers/sessionStorage';
import {
  ConfirmationPopupName,
  useEmailConfirmationPopupAnalytics,
} from 'hooks/analytics/useEmailConfirmationPopupAnalytics';
import { useEmailConfirmationMutation } from 'hooks/mutations/useEmailConfirmationMutation';
import { usePromotionPWA } from 'hooks/usePromotionPWA';
import { useToast } from 'hooks/useToast';
import { getUser } from 'store/auth/selectors';
import { closeConfirmMailFromNotificationPopup } from 'store/common/commonSlice';
import { getLastActiveUserProfileInfo } from 'store/profile/selectors';
import { closeSystemPopup } from 'store/systemPopup/systemPopupSlice';

import { ReactComponent as ConfirmationSendIcon } from 'assets/icons/confirmation-send.svg';
import { ReactComponent as LeftLinesIcon } from 'assets/icons/popups/lines-left.svg';
import { ReactComponent as RightLinesIcon } from 'assets/icons/popups/lines-right.svg';

import { BaseButton, ButtonTypes } from 'components/base/BaseButton';
import { Popup } from 'components/shared/Popup';
import { UserAvatar } from 'components/shared/UserAvatar';

import css from './mailConfirmProfilePopup.module.sass';

export const MailConfirmProfilePopup: React.FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const { showErrorToast, showSuccessToast } = useToast();

  const { trackEmailConfirmationPopup } = useEmailConfirmationPopupAnalytics();
  const { isCanPromotePWA, handlePWAPromotionButtonSubmit } = usePromotionPWA();

  const currentUser = useSelector(getUser);
  const lastActiveUserProfile = useSelector(getLastActiveUserProfileInfo);

  const handleResentSuccess = useCallback(() => {
    dispatch(closeConfirmMailFromNotificationPopup());
    dispatch(closeSystemPopup());
    setLastConfirmationProfilePopupShown();

    setIsConfirmationResent();
    showSuccessToast({ title: 'The mail has been resent!' });
  }, [dispatch, showSuccessToast]);

  const handleResentError = useCallback(
    (err: any) => {
      showErrorToast({
        title: getErrorMessageWithDefault(err),
      });
    },
    [showErrorToast]
  );

  const { mutate: sendConfirmation, isLoading } = useEmailConfirmationMutation({
    onError: handleResentError,
    onSuccess: handleResentSuccess,
  });

  const handleSubmit = useCallback(() => {
    sendConfirmation();

    trackEmailConfirmationPopup({
      popupName: ConfirmationPopupName.ConfirmationProfilePopup,
      operation: PopupOperation.ClickAccept,
    });
  }, [sendConfirmation, trackEmailConfirmationPopup]);

  const handleChangeMail = useCallback(() => {
    dispatch(closeConfirmMailFromNotificationPopup());
    dispatch(closeSystemPopup());
    setLastConfirmationProfilePopupShown();

    trackEmailConfirmationPopup({
      popupName: ConfirmationPopupName.ConfirmationProfilePopup,
      operation: PopupOperation.ClickChange,
    });

    history.push('/my-profile/settings/account/change-email');
  }, [dispatch, history, trackEmailConfirmationPopup]);

  const handleClose = useCallback(() => {
    const isPWAOnMailConfirmShown = getSessionStorageItem(
      IS_PWA_ON_MAIL_CONFIRM_SHOWN
    );

    dispatch(closeSystemPopup());
    setLastConfirmationProfilePopupShown();

    trackEmailConfirmationPopup({
      popupName: ConfirmationPopupName.ConfirmationProfilePopup,
      operation: PopupOperation.Close,
    });

    if (!isPWAOnMailConfirmShown && isCanPromotePWA) {
      handlePWAPromotionButtonSubmit(PWA_POPUPS.AfterProfile);
    }
  }, [
    dispatch,
    handlePWAPromotionButtonSubmit,
    isCanPromotePWA,

    trackEmailConfirmationPopup,
  ]);

  useEffect(() => {
    trackEmailConfirmationPopup({
      popupName: ConfirmationPopupName.ConfirmationProfilePopup,
      operation: PopupOperation.Show,
    });
  }, [trackEmailConfirmationPopup]);

  return (
    <Popup onClose={handleClose} bodyClassName={css.root}>
      <div className={css.header}>
        <LeftLinesIcon className={css.linesIcon} />
        <UserAvatar
          size="66px"
          className={css.avatar}
          url={MirrorService.resolveImagePath(lastActiveUserProfile?.photoUrl)}
        />
        <div className={css.sendIconWrapper}>
          <ConfirmationSendIcon className={css.sendIcon} />
        </div>
        <UserAvatar
          size="66px"
          className={css.avatar}
          url={MirrorService.resolveImagePath(
            currentUser?.photo_url || currentUser?.photos?.[0]?.big_url
          )}
        />
        <RightLinesIcon className={css.linesIcon} />
      </div>

      <p className={css.title}>Don&apos;t miss anything</p>
      <p className={css.subtitle}>Please confirm your email address</p>
      <p className={css.email}>{currentUser?.email || '---'}</p>

      <BaseButton
        className={css.changeBtn}
        type={ButtonTypes.Transparent}
        onClick={handleChangeMail}
        disabled={isLoading}
      >
        Change Email
      </BaseButton>

      <BaseButton
        className={css.submitBtn}
        type={ButtonTypes.Accent}
        onClick={handleSubmit}
        loading={isLoading}
      >
        Resend Email
      </BaseButton>
    </Popup>
  );
};
