import { useQuery, UseQueryOptions } from 'react-query';

import { FetchPWAProfilesResponse, WizardApi } from 'api/WizardApi';

export const PWA_PROFILES_QUERY_KEY = 'pwa-profiles-query';

export const usePWAProfilesQuery = (
  queryOptions?: UseQueryOptions<FetchPWAProfilesResponse>
) => {
  return useQuery<FetchPWAProfilesResponse>(
    PWA_PROFILES_QUERY_KEY,
    WizardApi.getPWAProfiles,
    {
      staleTime: 5 * 60 * 1000,
      ...queryOptions,
    }
  );
};
