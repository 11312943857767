import { useCallback } from 'react';

import { PopupOperation } from 'types/enums/PopupOperation';

import { TrackingApi } from 'api/TrackingApi';

export enum ConfirmationPopupName {
  ConfirmationProfilePopup = 'confirmation_profile_popup',
  ConfirmationNotification = 'confirmation_notification',
  ConfirmationBanner = 'confirmation_banner',
  ConfirmationAfterRegPopup = 'confirmation_after_reg',
  ConfirmationSessionResendPopup = 'confirmation_session_resend',
  ConfirmationSessionInboxPopup = 'confirmation_session_inbox',
  ConfirmationSessionRemindLaterPopup = 'confirmation_session_remind_later',
}

export const useEmailConfirmationPopupAnalytics = () => {
  const trackEmailConfirmationPopup = useCallback(
    ({
      popupName,
      operation,
    }: {
      popupName: ConfirmationPopupName;
      operation: PopupOperation;
    }) => {
      TrackingApi.trackPopup({
        name: popupName,
        operation,
        url: window.location.href,
      }).catch();
    },
    []
  );

  return {
    trackEmailConfirmationPopup,
  };
};
