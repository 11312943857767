import cookie from 'js-cookie';

import { MirrorService } from 'services/MirrorService';

import { getLocalStorageItem, setLocalStorageItem } from '../localStorage';

const EMAIL_CONFIRMATION_RESENT_COOKIE_KEY = 'email_confirmation';

const EMAIL_CONFIRMATION_NOTIFICATION_STORAGE_KEY = 'email_conf_notification';
const EMAIL_CONFIRMATION_PROFILE_POPUP_STORAGE_KEY = 'email_conf_profile_popup';

const EMAIL_CONFIRMATION_NOTIFICATION_DELAY = 6 * 60 * 60 * 1000; // ? 6h
const EMAIL_CONFIRMATION_PROFILE_POPUP_DELAY = 24 * 60 * 60 * 1000; // ? 24h

export const getMailConfirmationLink = () =>
  `https://mail.google.com/mail/mu/mp/#tl/search/in%3Aanywhere%20${MirrorService.siteName}`;

// ? email_confirmation
export const setIsConfirmationResent = () => {
  cookie.set(EMAIL_CONFIRMATION_RESENT_COOKIE_KEY, '1', {
    expires: 1,
  });
};

export const getIsConfirmationResent = () => {
  return cookie.get(EMAIL_CONFIRMATION_RESENT_COOKIE_KEY);
};

// ? EMAIL_CONF_NOTIFICATION
export const setIsConfirmationNotificationShown = () => {
  setLocalStorageItem(EMAIL_CONFIRMATION_NOTIFICATION_STORAGE_KEY, Date.now());
};

export const getIsConfirmationNotificationShown = () => {
  const lastShown = getLocalStorageItem(
    EMAIL_CONFIRMATION_NOTIFICATION_STORAGE_KEY
  );

  if (!lastShown) return false;

  try {
    return (
      Date.now() - Number(lastShown) < EMAIL_CONFIRMATION_NOTIFICATION_DELAY
    );
  } catch (error) {
    return false;
  }
};

// ? EMAIL_CONF_PROFILE_POPUP
export const setLastConfirmationProfilePopupShown = () => {
  setLocalStorageItem(EMAIL_CONFIRMATION_PROFILE_POPUP_STORAGE_KEY, Date.now());
};

export const getIsConfirmationProfilePopupShown = () => {
  const lastShown = getLocalStorageItem(
    EMAIL_CONFIRMATION_PROFILE_POPUP_STORAGE_KEY
  );

  if (!lastShown) return false;

  try {
    return (
      Date.now() - Number(lastShown) < EMAIL_CONFIRMATION_PROFILE_POPUP_DELAY
    );
  } catch (error) {
    return false;
  }
};
