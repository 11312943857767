import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { EmailSubscriptionStatus } from 'types/enums/EmailSubscriptionStatus';

import {
  getIsInitedPurchaseV1,
  getIsInitedPurchaseV2,
  getIsInitedPurchaseV3,
  getIsPurchaseV1PopupMustBeShown,
  getIsPurchaseV2PopupMustBeShown,
  getIsPurchaseV3PopupMustBeReInited,
  getIsPurchaseV3PopupMustBeShown,
  getIsPurchaseV3Shown,
  initPurchaseV1,
  initPurchaseV2,
  initPurchaseV3,
  reInitPurchaseV1,
  reInitPurchaseV2,
  setPurchaseV1Shown,
  setPurchaseV2Shown,
  setPurchaseV3Shown,
} from 'helpers/popups/creditsPurchase';
import {
  getIsConfirmationProfilePopupShown,
  getIsConfirmationResent,
} from 'helpers/popups/emailConfirmation';
import {
  getIsPWAAfterPurchasePopupMustBeShown,
  reInitPWAAfterPurchase,
  setPWAAfterPurchaseShown,
} from 'helpers/popups/pwaAfterPurchasse';
import { PWA_POPUPS } from 'helpers/pwa/constants';
import { getUser, getUserEmailSubscriptionStatus } from 'store/auth/selectors';
import {
  getIsEnabledPurchasePopupSelector,
  getIsEnabledPWAAfterPurchaseSelector,
  getIsWizardFinishedSelector,
} from 'store/common/selectors';
import {
  getInitPackageSelector,
  getIsInitPackageSelector,
  getIsUserSpentFreeCreditsSelector,
} from 'store/payment/selectors';
import { getLastActiveUserProfileInfo } from 'store/profile/selectors';
import { getActiveSystemPopupTypeSelector } from 'store/systemPopup/selectors';
import {
  getIsSystemPopupQueueLocked,
  setActiveSystemPopup,
  SystemPopupTypes,
} from 'store/systemPopup/systemPopupSlice';

import { usePromotionPWA } from './usePromotionPWA';

const PAGES_WITH_PURCHASE_POPUP = ['/user'];
const EXACT_PAGES_WITH_PURCHASE_POPUP = ['/finder', '/my-profile'];
const PAGES_WITH_PWA_AFTER_PURCHASE_POPUP = ['/user', '/finder'];
const PAGES_TO_IGNORE_CONFIRMATION = [
  '/dialogs',
  '/mails',
  '/upgrade',
  '/payment',
  '/wizard',
  '/user',
  '/disclosures-disclaimers',
  '/terms',
  '-policy',
];

const POPUP_QUEUE_LOCK_DELAY = 60 * 1000; // ? we can't show popup, if prev popup was closed not more than 60 sec ago

// TODO base logic of PWA queue
// 1. schedule popup
// 2. if another popup active - reschedule popup ( ! use priority here )
// 3. create POPUP context and decompose existing logic
// 4. add priority level for every popup

export const usePopupsQueue = () => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  const { isCanPromotePWA } = usePromotionPWA();

  const user = useSelector(getUser);
  const userEmailSubscriptionStatus = useSelector(
    getUserEmailSubscriptionStatus
  );
  const activeSystemPopupType = useSelector(getActiveSystemPopupTypeSelector);
  const isWizardFinished = useSelector(getIsWizardFinishedSelector);
  const isEnabledPurchasePopup = useSelector(getIsEnabledPurchasePopupSelector);
  const initPackage = useSelector(getInitPackageSelector);
  const isInitPackage = useSelector(getIsInitPackageSelector);
  const isUserSpentFreeCredits = useSelector(getIsUserSpentFreeCreditsSelector);
  const lastActiveUserProfile = useSelector(getLastActiveUserProfileInfo);
  const isEnabledPWAAfterPurchase = useSelector(
    getIsEnabledPWAAfterPurchaseSelector
  );

  // ? PURCHASE POPUP INIT START

  useEffect(() => {
    if (
      isWizardFinished &&
      isEnabledPurchasePopup &&
      isInitPackage &&
      !getIsInitedPurchaseV1()
    ) {
      initPurchaseV1();
    }
  }, [isWizardFinished, isEnabledPurchasePopup, isInitPackage]);

  useEffect(() => {
    if (
      isWizardFinished &&
      isEnabledPurchasePopup &&
      isInitPackage &&
      isUserSpentFreeCredits &&
      !getIsInitedPurchaseV2()
    ) {
      initPurchaseV2();
    }
  }, [
    isWizardFinished,
    isEnabledPurchasePopup,
    isInitPackage,
    isUserSpentFreeCredits,
  ]);

  useEffect(() => {
    if (
      getIsInitedPurchaseV1() &&
      !getIsInitedPurchaseV3() &&
      !getIsPurchaseV3Shown()
    ) {
      initPurchaseV3();
    }
  }, [isWizardFinished, isEnabledPurchasePopup, isInitPackage]);

  // ? PURCHASE POPUP INIT END

  useEffect(() => {
    // ? MAIL CONFIRM POPUP TRIGGER START

    const isConfirmationDisabledByPage = PAGES_TO_IGNORE_CONFIRMATION.some(
      (pageItem) => pathname.includes(pageItem)
    );

    if (
      lastActiveUserProfile?.ulid_id &&
      lastActiveUserProfile?.ulid_id !== user?.ulid_id &&
      userEmailSubscriptionStatus ===
        EmailSubscriptionStatus.ConfirmationSend &&
      !isConfirmationDisabledByPage &&
      !activeSystemPopupType &&
      !getIsConfirmationProfilePopupShown() &&
      !getIsConfirmationResent()
    ) {
      dispatch(
        setActiveSystemPopup({
          type: SystemPopupTypes.MailConfirmAfterProfile,
        })
      );

      return;
    }

    // ? MAIL CONFIRM POPUP TRIGGER END

    // ? PWA AFTER PURCHASE POPUP TRIGGER START

    const isPWAAfterPurchaseEnabledByPage =
      PAGES_WITH_PWA_AFTER_PURCHASE_POPUP.some((pageItem) =>
        pathname.includes(pageItem)
      );

    if (
      isCanPromotePWA &&
      isPWAAfterPurchaseEnabledByPage &&
      isEnabledPWAAfterPurchase &&
      getIsPWAAfterPurchasePopupMustBeShown()
    ) {
      if (
        activeSystemPopupType ||
        getIsSystemPopupQueueLocked(POPUP_QUEUE_LOCK_DELAY)
      ) {
        reInitPWAAfterPurchase();

        return;
      }
      dispatch(
        setActiveSystemPopup({
          type: SystemPopupTypes.PromotePWA,
          params: { name: PWA_POPUPS.AfterPurchase, isWizard: true },
        })
      );
      setPWAAfterPurchaseShown();

      return;
    }

    // ? PWA AFTER PURCHASE POPUP TRIGGER END

    // ? PURCHASE POPUP TRIGGER START

    const isPurchasePopupEnabledByPage =
      PAGES_WITH_PURCHASE_POPUP.some((pageItem) =>
        pathname.includes(pageItem)
      ) ||
      EXACT_PAGES_WITH_PURCHASE_POPUP.some((pageItem) => pageItem === pathname);

    if (!initPackage || !isPurchasePopupEnabledByPage) return;

    if (getIsPurchaseV1PopupMustBeShown()) {
      if (
        activeSystemPopupType ||
        getIsSystemPopupQueueLocked(POPUP_QUEUE_LOCK_DELAY)
      ) {
        reInitPurchaseV1();

        return;
      }

      dispatch(
        setActiveSystemPopup({
          type: SystemPopupTypes.Purchase,
          params: { creditsPackage: initPackage, popupName: 'purchase_v1' },
        })
      );
      setPurchaseV1Shown();

      return;
    }

    if (getIsPurchaseV2PopupMustBeShown()) {
      if (
        activeSystemPopupType ||
        getIsSystemPopupQueueLocked(POPUP_QUEUE_LOCK_DELAY)
      ) {
        reInitPurchaseV2();

        return;
      }

      dispatch(
        setActiveSystemPopup({
          type: SystemPopupTypes.Purchase,
          params: { creditsPackage: initPackage, popupName: 'purchase_v2' },
        })
      );
      setPurchaseV2Shown();

      return;
    }

    if (getIsPurchaseV3PopupMustBeShown()) {
      if (
        activeSystemPopupType ||
        getIsSystemPopupQueueLocked(POPUP_QUEUE_LOCK_DELAY)
      ) {
        initPurchaseV3();

        return;
      }

      dispatch(
        setActiveSystemPopup({
          type: SystemPopupTypes.Purchase,
          params: { creditsPackage: initPackage, popupName: 'purchase_v3' },
        })
      );
      setPurchaseV3Shown();
    }

    if (getIsPurchaseV3PopupMustBeReInited()) {
      initPurchaseV3();
    }

    // ? PURCHASE POPUP TRIGGER END
  }, [
    activeSystemPopupType,
    dispatch,
    initPackage,
    isCanPromotePWA,
    isEnabledPWAAfterPurchase,
    lastActiveUserProfile?.ulid_id,
    pathname,
    user?.ulid_id,
    userEmailSubscriptionStatus,
  ]);
};
