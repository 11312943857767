import { useCallback } from 'react';

import { MirrorService } from 'services/MirrorService';
import { PopupOperation } from 'types/enums/PopupOperation';

import { TrackingApi } from 'api/TrackingApi';

export const usePWAAnalytics = () => {
  const trackPWAShow = useCallback((name: string) => {
    TrackingApi.trackPopup({
      name,
      operation: PopupOperation.Show,
      url: window.location.href,
    }).catch();
  }, []);

  const trackPWAClose = useCallback((name: string) => {
    TrackingApi.trackPopup({
      name,
      operation: PopupOperation.Close,
      url: window.location.href,
    }).catch();
  }, []);

  const trackPWAClick = useCallback((name: string) => {
    TrackingApi.trackPopup({
      name,
      operation: PopupOperation.Click,
      url: window.location.href,
    }).catch();
  }, []);

  const trackPWAInstall = useCallback((isSystemInstall: boolean) => {
    TrackingApi.trackPWAInstall({
      icon: `${window.location.origin}/favicons/${MirrorService.siteName}-32x32.png`,
      is_system: isSystemInstall ? 1 : 0,
    }).catch();
  }, []);

  return {
    trackPWAShow,
    trackPWAClose,
    trackPWAClick,
    trackPWAInstall,
  };
};
