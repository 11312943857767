import { useQuery, UseQueryOptions } from 'react-query';

import { WizardApi, WizardFinderProfilesResponse } from 'api/WizardApi';

export const WIZARD_FINDER_QUERY_KEY = 'wizard-profiles-finder-query';

export const useWizardFinderProfilesQuery = (
  queryOptions?: UseQueryOptions<WizardFinderProfilesResponse>
) => {
  return useQuery<WizardFinderProfilesResponse>(
    WIZARD_FINDER_QUERY_KEY,
    WizardApi.getWizardFinderProfiles,
    {
      staleTime: 5 * 60 * 1000,
      ...queryOptions,
    }
  );
};
