import { PackageCurrency } from 'types/enums/payment/PackageCurrency';
import { Package } from 'types/interfaces/payment/Package';

const CURRENCY_SYMBOLS = {
  [PackageCurrency.USD]: '$',
  [PackageCurrency.CAD]: '$',
  [PackageCurrency.AUD]: '$',
  [PackageCurrency.NZD]: ' $',
  [PackageCurrency.GBP]: '£',
  [PackageCurrency.EUR]: ' €',
  [PackageCurrency.SEK]: ' kr',
  [PackageCurrency.NOK]: ' kr',
  [PackageCurrency.DKK]: ' kr',
  [PackageCurrency.PLN]: ' zł',
};

enum DURATION_LABELS {
  Day = 'day',
  Month = 'month',
  Year = 'year',
}

export const roundToTwoDigits = (value: number) =>
  (Math.round(value * 100) / 100).toFixed(2);

export const getCurrencySymbol = (currency: PackageCurrency) => {
  return CURRENCY_SYMBOLS[currency] ?? '';
};

export const getDurationLabel = (duration: number) => {
  if (duration < 28) return DURATION_LABELS.Day;
  if (duration === 28) return DURATION_LABELS.Month;
  return DURATION_LABELS.Year;
};

export const getOneCreditPrice = (packPrice: string, creditAmount: number) => {
  const value = parseFloat(packPrice) / creditAmount;
  return value.toFixed(2);
};

export const isKroneCurrency = (currency?: PackageCurrency) => {
  if (!currency) {
    return false;
  }

  return [
    PackageCurrency.SEK,
    PackageCurrency.NOK,
    PackageCurrency.DKK,
    PackageCurrency.PLN,
  ].includes(currency);
};

export const applyCurrencySymbol = (
  value: string | number,
  currency: PackageCurrency
) => {
  const currencySymbol = getCurrencySymbol(currency);

  return isKroneCurrency(currency)
    ? `${value} ${currencySymbol}`
    : `${currencySymbol}${value}`;
};

export const applyCurrency = (
  currency: PackageCurrency,
  cost?: string | number
) => {
  if (cost) {
    return applyCurrencySymbol(cost, currency);
  }

  return '';
};

export const getPackagePrice = (pack?: Package | null) => {
  if (!pack) return {};

  const { currency, display_price, duration, initial_price_full } = pack;

  const currencySymbol = getCurrencySymbol(currency);

  const initialPriceFull = roundToTwoDigits(initial_price_full);
  const displayPrice = roundToTwoDigits(display_price);

  const priceFullWithCurrency = `${applyCurrency(currency, initialPriceFull)}`;
  const displayPriceWithCurrency = `${applyCurrency(currency, displayPrice)}`;

  const displayPriceIntValue = displayPrice.split('.')[0] || 0;
  const displayPriceCentsValue = displayPrice.split('.')?.[1] || '00';

  const durationLabel = getDurationLabel(duration);

  return {
    currencySymbol,
    durationLabel,
    priceFullWithCurrency,
    displayPriceWithCurrency,
    displayPriceIntValue,
    displayPriceCentsValue,
  };
};
