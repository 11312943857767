import React, { useCallback, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { PopupOperation } from 'types/enums/PopupOperation';

import { Funnel, getFunnelSearchString } from 'helpers/funnels/funnels';
import { usePurchasePopupAnalytics } from 'hooks/analytics/usePurchasePopupAnalytics';
import { getCreditsCurrencySelector } from 'store/payment/selectors';
import { PurchasePopupParams } from 'store/systemPopup/systemPopupSlice';

import { ReactComponent as ContentIcon } from 'assets/icons/benefits/content.svg';
import { ReactComponent as MessagesIcon } from 'assets/icons/benefits/messages.svg';
import { ReactComponent as PhotosIcon } from 'assets/icons/benefits/photos.svg';
import { ReactComponent as CreditsIcon } from 'assets/icons/credits.svg';

import { BaseButton, ButtonTypes } from 'components/base/BaseButton';
import { PackageV2 } from 'components/sections/Payment/PackageV2';
import { Popup } from 'components/shared/Popup';

import css from './purchasePopup.module.sass';

interface Props {
  params: PurchasePopupParams;
  onClose: () => void;
}

export const PurchasePopup: React.FC<Props> = ({ params, onClose }) => {
  const { pathname } = useLocation();

  const { creditsPackage, popupName } = params;

  const { trackPurchasePopup } = usePurchasePopupAnalytics();

  const creditsCurrency = useSelector(getCreditsCurrencySelector);
  const benefits = useMemo(
    () => [
      {
        icon: <MessagesIcon className={css.icon} />,
        title: 'Send messages to anyone',
      },
      {
        icon: <PhotosIcon className={css.icon} />,
        title: 'Send photo in chat',
      },
      {
        icon: <ContentIcon className={css.icon} />,
        title: 'Open private content',
      },
    ],
    []
  );

  const handleClose = useCallback(() => {
    onClose();

    trackPurchasePopup({ popupName, operation: PopupOperation.Close });
  }, [onClose, popupName, trackPurchasePopup]);

  const handleClick = useCallback(() => {
    onClose();

    trackPurchasePopup({ popupName, operation: PopupOperation.Click });
  }, [onClose, popupName, trackPurchasePopup]);

  useEffect(() => {
    trackPurchasePopup({ popupName, operation: PopupOperation.Show });
  }, [popupName, trackPurchasePopup]);

  return (
    <Popup onClose={handleClose} popupClassName={css.popupBody}>
      <div className={css.root}>
        <h2 className={css.title}>Access all premium features</h2>

        {!!creditsPackage && (
          <PackageV2
            isInitPackage
            isMostPopularPackage={false}
            vertical={false}
            pack={creditsPackage}
          />
        )}

        <div className={css.benefits}>
          <h2 className={css.benefitsTitle}>
            With {creditsPackage?.credits_buy || 20} {creditsCurrency} you can
          </h2>

          <div>
            {benefits.map(({ title, icon }, index) => (
              <div className={css.benefit} key={index}>
                <p className={css.benefitIcon}>{icon}</p>
                <p className={css.benefitText}>{title}</p>
              </div>
            ))}
          </div>
        </div>

        <div className={css.btnContainer}>
          <BaseButton
            className={css.btn}
            type={ButtonTypes.Accent}
            to={`/payment/card/${creditsPackage?.id}?${getFunnelSearchString(
              Funnel.PurchasePopup,
              {
                success_url: pathname,
              }
            )}`}
            onClick={handleClick}
          >
            <CreditsIcon className={css.btnIcon} />
            <span>
              GET {creditsPackage?.credits_buy || 20} {creditsCurrency}
            </span>
          </BaseButton>
        </div>
      </div>
    </Popup>
  );
};
