import { useCallback } from 'react';

import { PopupOperation } from 'types/enums/PopupOperation';

import { TrackingApi } from 'api/TrackingApi';

export const usePurchasePopupAnalytics = () => {
  const trackPurchasePopup = useCallback(
    ({
      operation,
      popupName,
    }: {
      operation: PopupOperation;
      popupName: string;
    }) => {
      TrackingApi.trackPopup({
        name: popupName,
        operation,
        url: window.location.href,
      }).catch((err) => err);
    },
    []
  );

  return {
    trackPurchasePopup,
  };
};
