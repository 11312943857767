import { AppThunk } from 'store';

import { Package } from 'types/interfaces/payment/Package';
import { SubscriptionInfo } from 'types/interfaces/payment/SubscriptionInfo';

import { PackagesThemeGroups } from 'api/AuthApi';
import { PaymentApi } from 'api/PaymentApi';

import {
  setCreditPackages,
  setCredits,
  setGiftPackages,
  setPackagesTheme,
  setSubscriptionInfo,
  setSubscriptionPackages,
  updateCreditsInfo,
} from './paymentSlice';

const setDefaultPackAsActive = (packages: Package[] | null) =>
  Array.isArray(packages)
    ? packages.map((pack) =>
        pack.is_default ? { ...pack, active: true } : pack
      )
    : [];

export const setPayment =
  (payment: {
    packages: Package[];
    recurring_packages: Package[];
    gift_packages: Package[];
    theme: PackagesThemeGroups;
    credits: {
      amount: number;
      currency: string;
      spend_amount: number;
    };
    subscription: SubscriptionInfo;
  }): AppThunk =>
  (dispatch) => {
    dispatch(setCreditPackages(setDefaultPackAsActive(payment.packages)));
    dispatch(setGiftPackages(setDefaultPackAsActive(payment.gift_packages)));
    dispatch(
      setSubscriptionPackages(
        setDefaultPackAsActive(payment.recurring_packages)
      )
    );

    if (payment.credits) {
      dispatch(setCredits(payment.credits));
    }

    if (payment.theme) {
      dispatch(setPackagesTheme(payment.theme));
    }

    if (payment.subscription) {
      dispatch(setSubscriptionInfo(payment.subscription));
    }
  };

export const changeRefillStatus =
  (isRefill: boolean): AppThunk =>
  async (dispatch) => {
    await PaymentApi.changeRefillStatus(isRefill);

    dispatch(updateCreditsInfo({ isRefillEnabled: isRefill }));
  };

export const changeRefillPackage =
  (packageId: string): AppThunk =>
  async (dispatch) => {
    await PaymentApi.changeRefillPackage(packageId);

    dispatch(updateCreditsInfo({ refillPackage: packageId }));
  };
