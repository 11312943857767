import queryString from 'query-string';
import { v4 as uuidv4 } from 'uuid';

import { SelectOption } from 'types';
import { MediaType } from 'types/enums/MediaType';
import { PaymentProcessor } from 'types/enums/payment/PaymentProcessor';
import { PopupOperation } from 'types/enums/PopupOperation';
import { NotificationExtra } from 'types/interfaces/Notifications';

import { TRANSLATIONS_BUILD_SESSION_STORAGE_KEY } from 'helpers/constants';
import { getScreenOrientationWithSizes } from 'helpers/device';
import { getNetworkParams } from 'helpers/network';
import { prelandIdFormPathname } from 'helpers/route';
import { getSessionStorageItem } from 'helpers/sessionStorage';

import { httpClient } from './httpClient';

// TODO add tracking services and use them instead of using hooks in most cases

interface TrackResponse {
  trans_build_id: string;
}

interface ScreenParameters {
  screen: {
    orientation: 'landscape' | 'portrait';
    width: number;
    height: number;
  };
}

export type WizardActionName =
  | 'continue'
  | 'skip'
  | 'upload photo'
  | 'previous'
  | 'close'
  | 'take with camera'
  | 'upload from devise'
  | 'submit';

const getBuildVersionFromSessionStorage = () => {
  return getSessionStorageItem(TRANSLATIONS_BUILD_SESSION_STORAGE_KEY) || '0';
};

const getAppLoadParams = () => {
  const eventLoadEndAt = Date.now();

  return {
    id: window?.appLoadId,
    url: window.location.href,
    end_at: eventLoadEndAt,
    duration: eventLoadEndAt - window.appLoadStartTime || 0,
  };
};

const getAppLoadUserAttributes = () => {
  const {
    partner_id,
    partner_login_id,
    click_id,
    fbclid,
    gclid,
    wbraid,
    gbraid,
    ttclid,
    twclid,
  } = queryString.parse(window.location.search);

  return {
    partner_id,
    partner_login_id,
    click_id:
      click_id || fbclid || gclid || wbraid || gbraid || ttclid || twclid,
  };
};

export type PaymentFormEvents =
  | 'paymentDetails'
  | 'card'
  | 'error'
  | 'resize'
  | 'interaction'
  | 'formRedirect'
  | 'verify'
  | 'fail'
  | 'success'
  | 'submit'
  | 'mounted';

export type PaymentFormInteractionTarget = 'button' | 'input';

export type PaymentFormInteractionType = 'click' | 'focus' | 'blur' | 'change';

export type PaymentFormInteractionName =
  | 'submit'
  | 'googlePay'
  | 'applePay'
  | 'cardNumber'
  | 'cardCvv'
  | 'cardExpiry'
  | 'cardHolder'
  | 'zipCode';

interface TrackPaymentFormPayload {
  orderId: string;
  event: PaymentFormEvents;
  templateName: PaymentProcessor;
  payMethod?: string;

  interactionTarget?: PaymentFormInteractionTarget;
  interactionName?: PaymentFormInteractionName;
  interactionType?: PaymentFormInteractionType;

  cardFormIsValid?: boolean;
  cardFormTouched?: boolean;
  cardNumberIsValid?: boolean;
  cardNumberTouched?: boolean;
  cardCvvIsValid?: boolean;
  cardCvvTouched?: boolean;
  cardExpiryIsValid?: boolean;
  cardExpiryTouched?: boolean;
  zipCodeTouched?: boolean;
  zipCodeIsValid?: boolean;
  errorDeclineCode?: string;
  errorDeclineMessage?: string;
}

export const TrackingApi = {
  async track() {
    const prelandId = prelandIdFormPathname(document.location.pathname);
    const historyLength =
      window.history.length > 1 ? window.history.length : null;

    const { data } = await httpClient.get<TrackResponse>('/track', {
      params: {
        preland_id: prelandId,
        history_length: historyLength,
      },
    });

    return data;
  },

  trackError(payload: {
    type?: 'error' | 'warning';
    message: string;
    params?: any;
  }) {
    const url = payload.type === 'error' ? '/log/error' : '/log/warning';

    return httpClient.post(url, {
      message: payload.message,
      params: {
        ...payload.params,
        buildId: getBuildVersionFromSessionStorage(),
        front_user_agent: navigator?.userAgent,
      },
    });
  },

  trackRegistrationStep({
    prelandId,
    screenNumber,
    errorMessage,
    emailValidation,
    params,
    marketingParams,
  }: {
    prelandId: number;
    screenNumber: number;
    emailValidation?: boolean;
    errorMessage?: Record<string, string | string[]>;
    params?: Record<
      string,
      string | number | boolean | SelectOption[] | undefined
    >;
    marketingParams: Record<string, any>;
  }) {
    return httpClient.post('/preland/track', {
      id: window?.appLoadId,
      preland_id: prelandId,
      screen_number: screenNumber,
      email_validation: emailValidation,
      parameters: JSON.stringify(params || {}),
      ...marketingParams,
      ...(errorMessage && {
        error_message: JSON.stringify(errorMessage),
      }),
      url: window.location.href,
      name: `${window.location.host}${window.location.pathname}`,
    });
  },

  trackWizardStep({
    name,
    actionName,
    wizardId,
    screenNumber,
    params,
    errorMessage,
  }: {
    name: string;
    wizardId: number;
    screenNumber: number;
    actionName?: WizardActionName;
    params?: Record<string, any> | (string | number | SelectOption)[];
    errorMessage?: string;
  }) {
    return httpClient.post('/track/wizard', {
      app_load_id: window?.appLoadId,
      name,
      wizard_id: wizardId,
      screen_number: screenNumber,
      event: actionName,
      parameters: JSON.stringify(params || {}),
      error_message: errorMessage,
      url: window.location.href,
    });
  },

  trackPageLoad(
    payload: ScreenParameters & {
      url: string;
      duration: number;
      message?: 'not_found_page';
    }
  ) {
    window.pageLoadId = uuidv4();

    return httpClient.post('/track/events/page-load', {
      id: window?.appLoadId,
      page_load_id: window?.pageLoadId,
      url: payload.url,
      duration: payload.duration,
      screen: payload.screen,
      message: payload.message,
    });
  },

  trackScreenOrientation(payload: ScreenParameters) {
    return httpClient.post('/track/screen-rotate', payload);
  },

  trackPopup(payload: {
    name: string;
    operation: PopupOperation;
    url: string;
    clickDestination?: string;
  }) {
    return httpClient.post('/track/popup', {
      name: payload.name,
      operation: payload.operation,
      url: payload.url,
      click_destination: payload.clickDestination,
    });
  },

  trackPublicPopup(
    payload: ScreenParameters & {
      info: string;
      operation: string;
    }
  ) {
    return httpClient.post('/track/public/popup', {
      info: payload.info,
      operation: payload.operation,
      screen: payload.screen,
    });
  },

  trackClick(payload: {
    name: string;
    interaction_with_user_ulid: string;
    profiles_pack_hash?: string;
    url: string;
    origin: string;
  }) {
    return httpClient.post('/track/click', {
      name: payload.name,
      interaction_with_user_ulid: payload.interaction_with_user_ulid,
      url: payload.url,
      origin: payload.origin,
      ...(payload.profiles_pack_hash && {
        profiles_pack_hash: payload.profiles_pack_hash,
      }),
    });
  },

  trackReactChunkLoadEnd() {
    httpClient.post('/track/react-load-end', {
      ...getAppLoadParams(),
      ...getNetworkParams(),
      ...getAppLoadUserAttributes(),
    });
  },

  trackAppLoadEnd() {
    httpClient.post('/track/app-load-end', {
      ...getAppLoadParams(),
      ...getNetworkParams(),
      ...getAppLoadUserAttributes(),
    });
  },

  trackWsLoadSuccess() {
    httpClient.post('/track/websocket-success', {
      ...getAppLoadParams(),
      ...getNetworkParams(),
    });
  },

  trackWsLoadError(errorMessage: string) {
    httpClient.post('/track/websocket-fail', {
      ...getAppLoadParams(),
      ...getNetworkParams(),
      error_message: errorMessage,
    });
  },

  trackAdBlockStatus(
    payload: ScreenParameters & {
      value: number;
    }
  ) {
    return httpClient.post('/track/ad-block', {
      screen: payload.screen,
      value: payload.value,
      parameter: '',
    });
  },

  trackMessageReceived(payload: { messageId: number }) {
    return httpClient.get(`/track/message-received/${payload.messageId}`);
  },

  trackRequestReceived(payload: { requestId: number }) {
    return httpClient.get(
      `/track/chat-request-message-received/${payload.requestId}`
    );
  },

  trackMessageSent(payload: { messageId: number }) {
    return httpClient.get(`/track/message-sent/${payload.messageId}`);
  },

  trackRequestSent(payload: { requestId: number }) {
    return httpClient.get(
      `/track/chat-request-message-sent/${payload.requestId}`
    );
  },

  trackToast(
    payload: {
      action: string;
      sender_ulid: string;
      notification_id: number;
      notification_title: string;
      notification_description: string;
      url: string;
    } & NotificationExtra
  ) {
    return httpClient.post('/track/notification', payload);
  },

  trackInsufCredits(payload: {
    feature_type: 5 | 10; // 5 - send gift, 10 - send sticker
    insuf_amount: number;
    for_user_ulid: string;
  }) {
    return httpClient.post('/credit/track/insuf', payload);
  },

  trackMediaUpload(payload: {
    track_id: string;
    event_name: 'start' | 'fail' | 'success';
    placement: 'wizard' | 'mails' | 'chat' | 'edit_profile' | 'common';
    fail_reason?: string;
    load_time_ms?: string | number;
    media_id?: number;
    media_type: MediaType | null;
    type: string; // ? jpeg heic mp4 avif
    weight_kb: number; // ? kB
  }) {
    return httpClient.post('/track/media-upload-stage', payload);
  },

  trackPaymentFormLoaded({
    orderId,
    result,
    templateName,
    iframeSrc,
  }: {
    orderId: string;
    result: 1 | 0;
    templateName: PaymentProcessor;
    iframeSrc?: string;
  }) {
    return httpClient.get('/track/payment/init-result', {
      params: {
        order_id: orderId,
        result,
        payment_form_template_name: templateName,
        ...(Boolean(iframeSrc) && { form_script_src: iframeSrc }),
      },
    });
  },

  trackPaymentForm(payload: TrackPaymentFormPayload) {
    return httpClient.post('/track/payment/form-interact', {
      paymentFormTemplateName: payload.templateName,
      payMethod: payload.payMethod,
      interactionTarget: payload.interactionTarget,
      interactionName: payload.interactionName,
      interactionType: payload.interactionType,
      event: payload.event,
      orderId: payload.orderId,
      screen: getScreenOrientationWithSizes(),

      cardFormIsValid: payload.cardFormIsValid,
      cardFormTouched: payload.cardFormTouched,
      cardNumberIsValid: payload.cardNumberIsValid,
      cardNumberTouched: payload.cardNumberTouched,
      cardCvvIsValid: payload.cardCvvIsValid,
      cardCvvTouched: payload.cardCvvTouched,
      cardExpiryIsValid: payload.cardExpiryIsValid,
      cardExpiryTouched: payload.cardExpiryTouched,
      zipCodeTouched: payload.zipCodeTouched,
      zipCodeIsValid: payload.zipCodeIsValid,
      errorDeclineCode: payload.errorDeclineCode,
      errorDeclineMessage: payload.errorDeclineMessage,
    });
  },

  trackPWAInstall(payload: { is_system: number; icon: string }) {
    return httpClient.post('/pwa/install', {
      ...payload,
      url: window.location.href,
    });
  },
};
