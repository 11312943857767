import {
  getSessionStorageItem,
  removeSessionStorageItem,
  setSessionStorageItem,
} from '../sessionStorage';

const PWA_AFTER_PURCHASE_POPUP_STORAGE_KEY = 'pwa_after_purchase_popup';
const PWA_AFTER_PURCHASE_POPUP_SHOWN_STORAGE_KEY =
  'pwa_after_purchase_popup_shown';

const PWA_AFTER_PURCHASE_DELAY = 60 * 1000; // ? 1 min
const PWA_AFTER_PURCHASE_RE_INIT = 2 * 60 * 60 * 1000; // ? 2 h

export const initPWAAfterPurchase = () => {
  const initTime = getSessionStorageItem(PWA_AFTER_PURCHASE_POPUP_STORAGE_KEY);

  if (initTime && Number(initTime) + PWA_AFTER_PURCHASE_RE_INIT < Date.now()) {
    removeSessionStorageItem(PWA_AFTER_PURCHASE_POPUP_SHOWN_STORAGE_KEY);
  }

  setSessionStorageItem(PWA_AFTER_PURCHASE_POPUP_STORAGE_KEY, Date.now());
};

export const setPWAAfterPurchaseShown = () => {
  setSessionStorageItem(PWA_AFTER_PURCHASE_POPUP_SHOWN_STORAGE_KEY, 1);
};

export const reInitPWAAfterPurchase = () => {
  setSessionStorageItem(
    PWA_AFTER_PURCHASE_POPUP_STORAGE_KEY,
    Date.now() + PWA_AFTER_PURCHASE_DELAY
  );
};

export const getIsPWAAfterPurchasePopupMustBeShown = () => {
  const initTime = getSessionStorageItem(PWA_AFTER_PURCHASE_POPUP_STORAGE_KEY);
  const isShown = getSessionStorageItem(
    PWA_AFTER_PURCHASE_POPUP_SHOWN_STORAGE_KEY
  );

  return initTime && !isShown;
};
