export const PWA_SW_PATH = '/pwa-sw.js';

export const PWA_PARTNER_ID = 61;
export const PWA_INSTALLED_STORAGE_KEY = 'pwa_installed';
export const PWA_TRACKING_COOKIES_STORAGE_KEY = 'pwa_tracking';

export enum PWA_POPUPS {
  Promote = 'pwa_promote',
  SystemPWA = 'system_pwa',
  AfterRemainder = 'after_reminder_pop_up',
  AfterProfile = 'after_profile_pop_up',
  AfterWizard = 'after_wizard',
  AfterPurchase = 'pwa_after_purchase',
}

export const IS_PWA_ON_MAIL_CONFIRM_SHOWN = 'isPWAOnMailConfirmShown';
