import { useCallback } from 'react';

import { PopupOperation } from 'types/enums/PopupOperation';

import { TrackingApi } from 'api/TrackingApi';

const LOW_BALANCE_POPUP_NAME = 'credit_balance_is_low';

export const useLowBalanceAnalytics = () => {
  const trackLowBalanceShow = useCallback(() => {
    TrackingApi.trackPopup({
      name: LOW_BALANCE_POPUP_NAME,
      operation: PopupOperation.Show,
      url: window.location.href,
    }).catch();
  }, []);

  const trackLowBalanceClose = useCallback(() => {
    TrackingApi.trackPopup({
      name: LOW_BALANCE_POPUP_NAME,
      operation: PopupOperation.Close,
      url: window.location.href,
    }).catch();
  }, []);

  const trackLowBalanceClick = useCallback(() => {
    TrackingApi.trackPopup({
      name: LOW_BALANCE_POPUP_NAME,
      operation: PopupOperation.Click,
      url: window.location.href,
    }).catch();
  }, []);

  return {
    trackLowBalanceShow,
    trackLowBalanceClose,
    trackLowBalanceClick,
  };
};
