import cookie from 'js-cookie';
import queryString from 'query-string';

import { MirrorService } from 'services/MirrorService';
import { AppDomain } from 'types/enums/AppDomain';

import { getLocalStorageItem, setLocalStorageItem } from 'helpers/localStorage';
import { QueryKeys } from 'hooks/useSystemSearchQueries';

import { getIsDisabledAdvertisingCookies } from '../cookiesSettings';

const TRACKING_PARAMS_COOKIE_NAME = 'tracking_params';
const TRACKING_REFERRER_COOKIE_NAME = 'tracking_referer';
const PARTNER_ID_QUERY_NAME = 'partner_id';
const EXPIRE_TIME_IN_DAYS = 30;

export const setTrackingCookiesFromUrl = () => {
  const {
    referrer,
    location: { hostname, search },
  } = document;

  let trackingSearch = search;

  const {
    [PARTNER_ID_QUERY_NAME]: partnerId,
    [QueryKeys.Fbclid]: fbclid,
    [QueryKeys.Ttclid]: ttclid,
    [QueryKeys.Twclid]: twclid,
    [QueryKeys.Gclid]: gclid,
    [QueryKeys.Wbraid]: wbraid,
    [QueryKeys.Gbraid]: gbraid,
    [QueryKeys.ClickId]: clickId,
  } = queryString.parse(search);

  const matchingParam = fbclid || gclid || wbraid || gbraid || ttclid || twclid;

  // ? logic from useClickIdAssign hook
  if (matchingParam && !clickId) {
    const queryStringWithoutCLickId = queryString.exclude(search, [
      QueryKeys.ClickId,
    ]);

    trackingSearch = `${queryStringWithoutCLickId}&${QueryKeys.ClickId}=${matchingParam}`;
  }

  const currentDomain = MirrorService.domainWithoutSubdomain;
  const fromExternalDomain = (url: string) => !url.includes(hostname);

  if (partnerId && !getIsDisabledAdvertisingCookies()) {
    if (currentDomain !== AppDomain.MariaDates) {
      cookie.set(TRACKING_PARAMS_COOKIE_NAME, trackingSearch, {
        expires: EXPIRE_TIME_IN_DAYS,
        domain: `.${currentDomain}`,
      });
    }

    cookie.set(TRACKING_PARAMS_COOKIE_NAME, trackingSearch, {
      expires: EXPIRE_TIME_IN_DAYS,
      domain: `.${AppDomain.MariaDates}`,
    });
  }

  if (referrer && fromExternalDomain(referrer)) {
    cookie.set(TRACKING_REFERRER_COOKIE_NAME, referrer, {
      expires: EXPIRE_TIME_IN_DAYS,
      domain: `.${AppDomain.MariaDates}`,
    });
  }
};

export const setTrackingCookies = (trackingCookies: string) => {
  if (getIsDisabledAdvertisingCookies()) {
    setLocalStorageItem(TRACKING_PARAMS_COOKIE_NAME, trackingCookies);

    return;
  }

  const domain = MirrorService.domainWithoutSubdomain;

  cookie.set(TRACKING_PARAMS_COOKIE_NAME, trackingCookies, {
    expires: EXPIRE_TIME_IN_DAYS,
    domain: `.${domain}`,
  });
};

export const getTrackingCookie = () => {
  return (
    cookie.get(TRACKING_PARAMS_COOKIE_NAME) ||
    getLocalStorageItem(TRACKING_PARAMS_COOKIE_NAME) ||
    ''
  );
};
