import { useCallback } from 'react';
import cookie from 'js-cookie';
import { v4 as uuidv4, validate } from 'uuid';

import { MirrorService } from 'services/MirrorService';

import { VisitsApi } from 'api/VisitsApi';
import { useSystemSearchQueries } from 'hooks/useSystemSearchQueries';

export const INITIAL_ID_COOKIE_NAME = 'initial_id';
export const INITIAL_ID_EXPIRE = 36500;

export const getInitialIdFromCookie = () =>
  cookie.get(INITIAL_ID_COOKIE_NAME) || '';

export const useInitialId = () => {
  const { initialIdQuery } = useSystemSearchQueries();

  const generateInitialId = useCallback(() => uuidv4(), []);

  const getInitialIdFromAPI = useCallback(async (): Promise<any> => {
    try {
      const { data: initialIdFromFirstDomain } =
        await VisitsApi.fetchInitialIdFromFirstDomain(generateInitialId());
      const { data: initialIdFromServiceDomain } =
        await VisitsApi.fetchInitialIdFromServiceDomain(
          initialIdFromFirstDomain.initial_id
        );

      return initialIdFromServiceDomain.initial_id;
    } catch (err: any) {
      console.error(err);
    }

    return generateInitialId();
  }, [generateInitialId]);

  const setInitialIdToCookie = useCallback((initialId: string) => {
    const currentDomain = MirrorService.domainWithoutSubdomain;

    cookie.set(INITIAL_ID_COOKIE_NAME, initialId, {
      domain: `.${currentDomain}`,
      expires: INITIAL_ID_EXPIRE,
      sameSite: 'None',
      secure: true,
    });
  }, []);

  const getInitialId = useCallback((): Promise<string> => {
    return new Promise((resolve) => {
      const initialIdFromCookie = getInitialIdFromCookie();

      const isInitialIdFromCookieValid =
        initialIdFromCookie && validate(initialIdFromCookie);
      const isInitialIdFromQueryValid =
        initialIdQuery.value && validate(initialIdQuery.value);

      if (
        (initialIdFromCookie && !initialIdQuery.value) ||
        (initialIdQuery.value === initialIdFromCookie &&
          isInitialIdFromCookieValid)
      ) {
        VisitsApi.fetchInitialIdFromServiceDomain(initialIdFromCookie);

        resolve(initialIdFromCookie);
        return;
      }

      if (initialIdQuery.value && isInitialIdFromQueryValid) {
        VisitsApi.fetchInitialIdFromServiceDomain(initialIdQuery.value);

        resolve(initialIdQuery.value);
        return;
      }

      getInitialIdFromAPI().then(resolve);
    });
  }, [getInitialIdFromAPI, initialIdQuery.value]);

  return {
    getInitialId,
    getInitialIdFromCookie,
    setInitialIdToCookie,
  };
};
