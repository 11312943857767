import { getLocalStorageItem, setLocalStorageItem } from '../localStorage';
import {
  getSessionStorageItem,
  removeSessionStorageItem,
  setSessionStorageItem,
} from '../sessionStorage';

const PURCHASE_POPUP_V1_STORAGE_KEY = 'purchase_popup_v1';
const PURCHASE_POPUP_V2_STORAGE_KEY = 'purchase_popup_v2';
const PURCHASE_POPUP_V3_STORAGE_KEY = 'purchase_popup_v3';

const PURCHASE_POPUP_V1_SHOWN_STORAGE_KEY = 'purchase_popup_v1_shown';
const PURCHASE_POPUP_V2_SHOWN_STORAGE_KEY = 'purchase_popup_v2_shown';
const PURCHASE_POPUP_V3_SHOWN_STORAGE_KEY = 'purchase_popup_v3_shown';

const PURCHASE_POPUP_LONG_DELAY = 5 * 60 * 1000; // ? 5 min
const PURCHASE_POPUP_SHORT_DELAY = 60 * 1000; // ? 1 min
const PURCHASE_POPUP_RE_INIT = 60 * 60 * 1000; // ? 1 h

// ? POPUP V1

export const initPurchaseV1 = () => {
  setLocalStorageItem(PURCHASE_POPUP_V1_STORAGE_KEY, Date.now());
  setSessionStorageItem(PURCHASE_POPUP_V3_SHOWN_STORAGE_KEY, Date.now());
};

export const setPurchaseV1Shown = () => {
  setLocalStorageItem(PURCHASE_POPUP_V1_SHOWN_STORAGE_KEY, 1);
};

export const reInitPurchaseV1 = () => {
  setLocalStorageItem(
    PURCHASE_POPUP_V1_STORAGE_KEY,
    Date.now() + PURCHASE_POPUP_SHORT_DELAY - PURCHASE_POPUP_LONG_DELAY
  );
};

export const getIsInitedPurchaseV1 = () =>
  getLocalStorageItem(PURCHASE_POPUP_V1_STORAGE_KEY);

export const getIsPurchaseV1PopupMustBeShown = () => {
  const initTime = getLocalStorageItem(PURCHASE_POPUP_V1_STORAGE_KEY);
  const isShown = getLocalStorageItem(PURCHASE_POPUP_V1_SHOWN_STORAGE_KEY);

  if (!initTime || isShown) return false;

  try {
    return Number(initTime) + PURCHASE_POPUP_LONG_DELAY < Date.now();
  } catch (error) {
    return false;
  }
};

// ? POPUP V2

export const initPurchaseV2 = () => {
  setLocalStorageItem(PURCHASE_POPUP_V2_STORAGE_KEY, Date.now());
};

export const reInitPurchaseV2 = () => {
  setLocalStorageItem(
    PURCHASE_POPUP_V2_STORAGE_KEY,
    Date.now() + PURCHASE_POPUP_SHORT_DELAY
  );
};

export const setPurchaseV2Shown = () => {
  setLocalStorageItem(PURCHASE_POPUP_V2_SHOWN_STORAGE_KEY, 1);
};

export const getIsInitedPurchaseV2 = () =>
  getLocalStorageItem(PURCHASE_POPUP_V2_STORAGE_KEY);

export const getIsPurchaseV2PopupMustBeShown = () => {
  const initTime = getLocalStorageItem(PURCHASE_POPUP_V2_STORAGE_KEY);
  const isShown = getLocalStorageItem(PURCHASE_POPUP_V2_SHOWN_STORAGE_KEY);

  if (!initTime || isShown) return false;

  try {
    return Number(initTime) < Date.now();
  } catch (error) {
    return false;
  }
};

// ? POPUP V3

export const initPurchaseV3 = () => {
  setLocalStorageItem(PURCHASE_POPUP_V1_SHOWN_STORAGE_KEY, 1);
  setLocalStorageItem(PURCHASE_POPUP_V2_SHOWN_STORAGE_KEY, 1);
  setLocalStorageItem(PURCHASE_POPUP_V2_STORAGE_KEY, Date.now());

  setSessionStorageItem(PURCHASE_POPUP_V3_STORAGE_KEY, Date.now());
  removeSessionStorageItem(PURCHASE_POPUP_V3_SHOWN_STORAGE_KEY);
};

export const setPurchaseV3Shown = () => {
  setSessionStorageItem(PURCHASE_POPUP_V3_SHOWN_STORAGE_KEY, 1);
};

export const getIsInitedPurchaseV3 = () =>
  getSessionStorageItem(PURCHASE_POPUP_V3_STORAGE_KEY);

export const getIsPurchaseV3Shown = () =>
  getSessionStorageItem(PURCHASE_POPUP_V3_SHOWN_STORAGE_KEY);

export const getIsPurchaseV3PopupMustBeShown = () => {
  const initTime = getSessionStorageItem(PURCHASE_POPUP_V3_STORAGE_KEY);
  const isShown = getSessionStorageItem(PURCHASE_POPUP_V3_SHOWN_STORAGE_KEY);

  if (!initTime || isShown) return false;

  try {
    return Number(initTime) + PURCHASE_POPUP_SHORT_DELAY < Date.now();
  } catch (error) {
    return false;
  }
};

export const getIsPurchaseV3PopupMustBeReInited = () => {
  const initTimeV2 = getLocalStorageItem(PURCHASE_POPUP_V2_STORAGE_KEY);
  const initTimeV3 = getSessionStorageItem(PURCHASE_POPUP_V3_STORAGE_KEY);
  const isShownV1 = getLocalStorageItem(PURCHASE_POPUP_V1_SHOWN_STORAGE_KEY);
  const isShownV2 = getLocalStorageItem(PURCHASE_POPUP_V2_SHOWN_STORAGE_KEY);
  const isShownV3 = getSessionStorageItem(PURCHASE_POPUP_V3_SHOWN_STORAGE_KEY);

  if (isShownV1 && isShownV2 && isShownV3) {
    return Number(initTimeV2) + PURCHASE_POPUP_RE_INIT < Date.now();
  }

  if (!initTimeV3 || !isShownV3) return false;

  try {
    return Number(initTimeV3) + PURCHASE_POPUP_RE_INIT < Date.now();
  } catch (error) {
    return false;
  }
};
