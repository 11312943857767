import React from 'react';

import { MirrorService } from 'services/MirrorService';

import { PresentSentPopupParams } from 'store/systemPopup/systemPopupSlice';

import { BaseButton, ButtonTypes } from 'components/base/BaseButton';
import { Popup } from 'components/shared/Popup';

import css from './presentSentPopup.module.sass';

interface Props {
  params: PresentSentPopupParams;
  onClose: () => void;
}

export const PresentSentPopup: React.FC<Props> = ({ params, onClose }) => {
  return (
    <Popup>
      <div className={css.root}>
        <img
          className={css.image}
          src={MirrorService.resolveImagePath(params.presentSrc)}
        />
        <h2 className={css.title}>The request has been sent</h2>
        <p className={css.subtitle}>
          Your present is waiting for confirmation from {params.contactName}.
        </p>

        <BaseButton
          className={css.btn}
          type={ButtonTypes.Accent}
          onClick={onClose}
        >
          Back to site
        </BaseButton>
      </div>
    </Popup>
  );
};
