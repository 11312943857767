import { useCallback } from 'react';

import { PopupOperation } from 'types/enums/PopupOperation';

import { TrackingApi } from 'api/TrackingApi';

const ONLINE_REACTIVATION_POPUP_NAME = 'online_reactivation';

export const useReactivationPopupAnalytics = () => {
  const trackPopupShow = useCallback(() => {
    TrackingApi.trackPopup({
      name: ONLINE_REACTIVATION_POPUP_NAME,
      operation: PopupOperation.Show,
      url: window.location.href,
    }).catch();
  }, []);

  const trackPopupClick = useCallback(() => {
    TrackingApi.trackPopup({
      name: ONLINE_REACTIVATION_POPUP_NAME,
      operation: PopupOperation.Click,
      url: window.location.href,
    }).catch();
  }, []);

  return {
    trackPopupShow,
    trackPopupClick,
  };
};
