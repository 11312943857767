export enum PackageCurrency {
  USD = 'USD',
  CAD = 'CAD',
  AUD = 'AUD',
  GBP = 'GBP',
  EUR = 'EUR',
  SEK = 'SEK',
  NOK = 'NOK',
  DKK = 'DKK',
  NZD = 'NZD',
  PLN = 'PLN',
}
