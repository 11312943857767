import React, { memo } from 'react';

import { Package as PackageType } from 'types/interfaces/payment/Package';

import { PackageV2TypeColumn } from '../PackageV2TypeColumn';
import { PackageV2TypeRow } from '../PackageV2TypeRow';

interface Props {
  pack: PackageType;
  isInitPackage: boolean;
  isMostPopularPackage: boolean;
  vertical?: boolean;
  onClick?: () => void;
}

export const PackageV2: React.FC<Props> = memo(
  ({ pack, vertical, isInitPackage, isMostPopularPackage, onClick }) => {
    if (vertical)
      return (
        <PackageV2TypeColumn
          pack={pack}
          isMostPopular={isMostPopularPackage}
          onClick={onClick}
        />
      );

    return (
      <PackageV2TypeRow
        pack={pack}
        isInitPackage={isInitPackage}
        onClick={onClick}
      />
    );
  }
);
